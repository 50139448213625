import React, { Component } from "react";
import image from '../images/meet-russell.png';
import ReactTypingEffect from 'react-typing-effect';
import { Link } from "react-scroll";


function About() {
  return (
    <section id="about" className="relative mx-auto md:min-h-screen flex flex-col flex-wrap justify-center items-center motion-safe:animate-fadeIn snap-start">
      <div className="flex flex-col text-white flex-wrap sm:flex-nowrap md:text-left items-center px-5 sm:px-10 lg:py-20 justify-center md:justify-start">
        <div className="xl:w-3/4 w-100 text-left text-center">
          <div className="font-heading font-black text-md md:text-2xl lg:text-3xl">Hey! I'm <span className="text-orange-400">Russell Benton</span></div>  
          <h1 className="my-4 text-xl md:text-4xl lg:text-5xl leading-tight">
            Front End Developer in Sydney
            <br className="hidden md:inline"/> 
            Crafting High-Performing Websites
          </h1>
          <div className="my-4">
            <p className="lg:text-xl text-base text-grey-400" >
              With a passion for crafting <b>high-performing websites</b>, I specialise in creating <b>fast-loading</b>, <b>user-friendly</b> experiences that drive results for my clients. Leveraging my expertise in platforms like Shopify, WordPress, and frameworks like Vue.js and React, I bring my clients online visions to life. I take pride in delivering <b>impactful websites</b> that not only captivate users but also achieve tangible business goals. Take a moment to explore my portfolio and discover how I can help transform your online presence into a powerful tool for success.
            </p>
            {/*<Link hashSpy={true} spy={true} smooth={true} duration={500} to="experience" className="btn text-white text-sm mb-8 lg:mb-0 lg:text-xl bg-orange-400 border-orange-400 py-2 px-4 lg:px-4 lg:py-4 ">
              Learn more
            </Link>
            <Link hashSpy={true} spy={true} smooth={true} duration={500} to="contact" className="btn text-white text-sm mb-8 lg:mb-0 lg:text-xl ml-4 py-2 px-4 lg:px-4 lg:py-4 ">
              Contact
            </Link>*/}
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center mb-12 md:my-2">
          {/*<div className="mr-2 mb-2 md:m-2">
            <img className="h-8 md:h-16 rounded-r-md bg-white shadow-sm pr-1" src={require('../images/trust/google-partner-logo.png')} alt="Google Partner Badge" title="Google Partner"/>
          </div>*/}
          <div className="mr-2 mb-2 md:m-2">
            <img className="h-8 md:h-16 rounded-r-md bg-white shadow-sm p-2" src={require('../images/trust/shopify-partner-logo.png')} alt="Shopify Partner Badge" title="Shopify Partner"/>
          </div>
          {/*<div className="mr-2 mb-2 md:m-2">
            <img className="h-8 md:h-16 rounded-r-md bg-white shadow-sm" src={require('../images/trust/meta-partner-logo.png')} alt="Shopify Partner Badge" title="Shopify Partner"/>
          </div>*/}
          <div className="mr-2 mb-2 md:m-2">
            <img className="h-8 md:h-16 rounded-r-md bg-white shadow-sm p-3" src={require('../images/trust/w3c-logo.png')} alt="W3C Certification Badge" title="W3C Certified Developer"/>
          </div>
          <div className="mr-2 mb-2 md:m-2">
            <img className="h-8 md:h-16 rounded-r-md bg-white shadow-sm p-1" src={require('../images/trust/shopify-expert-logo.png')} alt="W3C Certification Badge" title="W3C Certified Developer"/>
          </div>
        </div>
      </div>
      <Link hashSpy={true} spy={true} smooth={true} duration={500} to="experience" className="bouncingArrow">
        <svg className="h-10 w-10 text-orange-400 m-auto"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
        </svg>
      </Link>
    </section>
  );
}

export default About;
