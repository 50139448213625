import React, { Component } from "react";
import { websites } from "./data/work.js";
import { tags } from "./data/tags.js";
import LazyLoad from "react-lazyload";
import Modal from "./Modal.js";
import { Link } from "react-scroll";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserExperienceUXDesign from '../pages/UserExperienceUXDesign';

class Work extends Component {
  state = {
    showModal: false,
    selectedProject: false,
    filteredTag: null,
    filteredWebsites: websites.filter(
      (website) => website.active !== false
    ), // add filteredWebsites to state
    websites: websites.filter((website) => website.active !== false),
  };

  openModal = ({ project }) => {
    this.setState((prevState) => ({
      selectedProject: project,
      showModal: !prevState.showModal,
    }));
  };

  setFilteredTag = ({ tag }) => {
    this.setState({
      filteredTag: tag.name,
      filteredWebsites: this.state.websites.filter(
        (website) =>
          website.tags.includes(tag.name) && website.active !== false
      ), // use tag.name instead of this.state.filteredTag
    });
  };

  renderProjects() {
    const { filteredTag, filteredWebsites } = this.state;
    return filteredWebsites.map((project, index) => (
      <div
        target="_blank"
        key={index}
        onClick={() => this.openModal({ project })}
        className="shrink-0 w-[calc(50%-1rem)] md:w-[calc(20%-1rem)] lg:w-[calc(20%-1rem)] shadow-lg hover:shadow-2xl hover:scale-105 hover:rotate-1 rounded-lg ease-in-out transition duration-300 h-24 md:h-40 overflow-hidden cursor-zoom-in relative"
      >
        <div
          style={{ backgroundColor: project.primaryColor }}
          className="flex items-center justify-center flex-col  w-full z-10 text-black h-full ease-in-out duration-300"
        >
          <img
            loading="lazy"
            className={
              "max-w-2/3 lg:max-w-2/3 w-2/3 lg:w-2/3 max-h-20 m-auto object-contain " +
              (project.invert === true && "brightness-0 invert")
            }
            src={require("../images/logos/" + project.logo)}
            alt={project.name}
          />
          <h3 className="text-2xl hidden font-bold py-2 text-center">
            {project.name}
          </h3>
          {filteredTag !== null && this.renderTags(project)}
        </div>
      </div>
    ));
  }

  renderTags(project) {
    return project.tags.map((tag, index) => (
      <p key={index}>{tag.name}</p>
    ));
  }

  render() {
    const { showModal, selectedProject } = this.state;
    return (
      <>
        {showModal && (
          <Modal
            toggle={showModal}
            project={selectedProject}
            modalAction={this.openModal}
          />
        )}
        <section
          id="work"
          className="container relative mx-auto w-full overflow-hidden sm:min-h-screen px-5  border-b border-b-1 border-orange-200 text-black flex flex-col flex-wrap items-center motion-safe:animate-fadeIn snap-start"
        >
          <h2 className="mb-2">
            Work<span className="text-orange-400">.</span>
          </h2>
          <h3 className="mb-8 text-xl text-center">Transform Your Online Vision Into Reality</h3>
            {/* 
          <ul className="mb-8 max-w-5xl text-center">
            {tags
              .filter((tag) => tag.active !== false)
              .map((tag, index) => (
                <li
                  key={index}
                  onClick={() => this.setFilteredTag({ tag })}
                  className="px-2 py-1 inline-block m-1 bg-white hover:bg-orange-400 shadow hover:text-white cursor-pointer ease-in-out transition rounded-md text-sm lg:text-base cursor-pointer text-black"
                >
                  {tag.name}
                </li>
              ))}
          </ul>
          */}
          <div className="w-full lg:w-full pb-8">
            <div className="flex flex-wrap gap-4 w-full overflow-y scroll items-center justify-center pb-8">
              {this.renderProjects()}
            </div>
          </div>
          <Link
            to={<UserExperienceUXDesign/>}
            path="/user-experience-ux-design"
            className="bouncingArrow inline-block mt-4"
          >
            <svg
              className="h-8 w-8 text-orange-500 m-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </Link>
        </section>
      </>
    );
  }
}

export default Work;
