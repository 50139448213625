export const testimonials = [
	{
		name: "Scott Fuller",
		company: "Bogan Apparel",
		websiteUrl: "https://www.boganapparel.com.au/",
    logo: "zestxldn-logo.png",
    authorImage: "testimonial-placeholder.jpg",
    text: "I was recommended Russell to assist in our websites transition from Square space to Shopify. I tried to shop around and find cheaper quotes (as we all do) but honestly I needed some one I could trust and it turns out his pricing for what he has produced is an absolute bargain and the best I could find! After contacting Russell he was keen to arrange a zoom meeting so we could discuss exactly what us as a company wanted and what could be achieved, Russell was very critical in finding things he could improve on our current site, which I honestly found so helpful and truly appreciated, he also shared some of his other projects and was very open about pricing from the start. Throughout, communication has been prompt and clear. He advised but did not push for one of the paid themes on Shopify, he makes no financial gains from this but allows him to really show off the skills he is capable of achieving due to being able to code things uniquely and add multiple plug ins! Not only have we now got an amazing website which helps drives customers past the finish line, but also drives them to have a bigger total spend with each purchase due to the awesome upsells! I feel Russell really went above and beyond on this project and truly exceeded my expectations.  Even if he did not know the answer to some of my complex ideas I had, he would seek and find them for us! He has also helped to teach me how to understand shopify more which is also great so I can continue to do minor updates myself. I would recommend Russell to any one just starting out with a project or similar to us where we wanted a more productive and professional website which brings more sales and returning customers, or to be honest any site which is looking to become or increase their success in sales! I will be using Russell again in the future for any new updates and would be happy to recommend to any one! Feel free to check out our site built by Russell at Boganapparel.com.au. 10/10",
    role: "Private Chef in London"
	},
	{
		name: "Billy Polson",
		company: "Australian Internet Advertising",
		websiteUrl: "https://www.aiad.com.au/",
    logo: "aiad.png",
    authorImage: "testimonial-billy-polson.webp",
    text: "Australian Internet Advertising made the right choice when they hired Russell Benton as their front end developer. He built a lightning-fast website for our agency using Vuejs and TailwindCss, and the results have been nothing short of amazing. Not only do our clients love the speedy user experience, but our conversion rates and SEO ranking have improved dramatically. Impressed with Russell's expertise and commitment to delivering the best possible website, we went on to hire him for our own clients. And he has been a trusted contractor for the past three years, consistently delivering top-notch work. We highly recommend him to anyone looking for a top-notch front end developer.",
    role: "Founder"
	},
	{
		name: "George J Iskander",
		company: "Cookie Diet Australia",
		websiteUrl: "https://www.cookiediet.com.au/",
		logo: "cookiediet.png",
		authorImage: "testimonial-george-iskander.jpeg",
		text: "Russell Benton has been a tremendous asset for my business, Cookie Diet. As our go-to front end developer for our Shopify website, Russell has consistently provided exceptional support and maintenance for the past two years. He's quick to respond to any needs and always goes above and beyond to make sure our website is functioning optimally. The site refresh he carried out has been a major boost to our business, improving our conversion rates and reaching a wider audience. I couldn't be more pleased with Russell's skills and dedication to providing the best possible service. I highly recommend him to any business searching for a talented front end developer.",
		role: ""
	},
	{
		name: "Edward Donaldson",
		company: "Zest X Ldn",
		websiteUrl: "https://www.zestxldn.co.uk/",
    logo: "zestxldn-logo.png",
    authorImage: "testimonial-edward-zestxldn.jpg",
    text: "I hired Russell to build a website for my private chef business, Zest X Ldn, and I was blown away by the final product. Russell was able to take my custom design and turn it into a beautiful, functional Wordpress website that has helped my business grow. He was professional, timely, and a pleasure to work with. I would highly recommend Russell to anyone looking for a top-notch front end developer!",
    role: "Private Chef in London"
	}
]
