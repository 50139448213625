import React, { Component, useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Modal(props) {
  const modalState = props.toggle;
  const modalAction = props.modalAction;
  const selectedProjectData = props.project;
  useEffect(() => {
    if (modalState) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalState]);
  return (
    <div className={"fixed z-10 top-0 w-full left-0 " + (!modalState ? "hidden" : "block")} id="modal">
      {selectedProjectData &&
        <div className="flex items-center justify-center pt-12 pb-4 px-4 text-center sm:block sm:p-0">

          <div onClick={modalAction} className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>

          <div onClick={modalAction} className="absolute right-2 top-2 text-white cursor-pointer text-4xl">&#10006;</div>
          <span className="sm:inline-block text-white sm:align-middle sm:h-screen">&#8203;</span>

          <div onClick={modalAction}
            className="inline-block w-full rounded-md overflow-hidden max-h-90 md:w-full md:px-20 transform transition-all sm:my-8 sm:align-middle relative"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="flex flex-col sm:flex-row overflow-auto max-h-screen justify-center">
              <div className="inline-flex items-center w-full max-h-90 sm:w-80 mb-4 md:mb-0">
                <div className="sticky top-0 shadow-lg bg-white p-4 md:py-8 md:px-4 md:mr-8 text-black rounded-lg overflow-hidden">
                  {/*}<h2>{selectedProjectData.name}</h2> */}

                  <div
                      className={"md:-mt-8 md:-mx-4 mb-6" + (selectedProjectData.invert === true ? " p-4" : " py-4")}
                      style={{backgroundColor: selectedProjectData.primaryColor }}
                  >
                  <LazyLoad height={200}>
                    <img
                      className={"max-h-20 mx-auto " + (selectedProjectData.invert === true ? "brightness-0 invert" : "")}
                      loading="lazy"
                      src={require('../images/logos/' + selectedProjectData.logo)} alt={selectedProjectData.name}
                    />
                  </LazyLoad>
                  </div>
                  <p className="text-black mb-3 text-sm">{selectedProjectData.description}</p>
                  <ul className="mb-4">
                    {selectedProjectData.tags.map((tag, index) => (
                      <li key={index} className="py-1 px-2 inline-block mr-1 mt-1 bg-slate-300 text-sm rounded-sm text-black">{tag}</li>
                    ))}
                  </ul>
                  <a
                    href={selectedProjectData.url}
                    style={
                      selectedProjectData.primaryColor !== null && selectedProjectData.primaryColor !== "#ffffff"
                      ? {backgroundColor: selectedProjectData.primaryColor}
                      : {backgroundColor: '#000'}
                    }
                    target="_blank"
                    className="block bg-orange-400 mb-2 border-2 border-solid border-inherit hover:text-white bg-black text-center w-full right-0 left-0 bottom-0 px-4 py-2 text-base text-white"
                  >
                    Visit Website
                  </a>
                  <a
                    style={
                      selectedProjectData.primaryColor !== null && selectedProjectData.primaryColor !== "#ffffff"
                      ? {borderColor: selectedProjectData.primaryColor, color: selectedProjectData.primaryColor}
                      : {backgroundColor: '#000'}
                    }
                    target="_blank"
                    className="block hover:text-white border-solid border-2 text-center w-full right-0 left-0 bottom-0 px-4 py-2 text-base text-white"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div className="inline-flex overflow-scroll w-fit max-h-90 items-start rounded-lg">
                <picture>
                    <img src={require('../images/screenshots/' + selectedProjectData.screenshot[0])} alt={selectedProjectData.name} alt='hero' />
                </picture>
              </div>
            </div>

          </div>
        </div>
      }
    </div>
  )
}

export default Modal
