import React from "react"
import { services } from './data/services.js';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-scroll";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

function Services() {
  return (
    <section id="services" className="relative flex border-b border-b-1 border-orange-200 flex-col items-center justify-center mx-auto px-5 md:px-10 md:min-h-screen py-20 text-center text-black motion-safe:animate-fadeIn snap-start">
      <h2 className="mb-8">Services<span className="text-orange-400">.</span></h2>
      <div className="w-full gap-4 flex flex-wrap text-center items-stretch text-center justify-center flex-wrap">
      {services.map((service, key) => {
        return (
          <article className="w-[calc(100%-1rem)] border-t-8 border-orange-400 group sm:w-[calc(25%-1rem)] border-box rounded-lg flex flex-wrap items-center relative justify-center text-center shadow-md hover:shadow-lg hover:z-2 bg-white py-6 px-4 rounded-lg" key={key}>
            {/*<div className="absolute right-0 left-0 top-0 bottom-0 w-full flex p-2 items-center justify-center hover:bg-black z-0 opacity-0 hover:opacity-95"></div>*/}
            <img className="w-1/3 lg:w-1/4 z-1 bg-white group-hover:animate-pulse rounded-lg invert-1 mb-2 group-hover:scale-105 ease-in-out transition duration-300" alt={service.iconAlt} src={require('../images/services/edit/' + service.icon)}/>
            <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
            <p className="text-base text-gray-700">{service.description}</p>
            {/*
              <button className="btn w-full justify-center group-hover:bg-orange-400 group-hover:rotate-1 rotate-0 ease-in-out transition duration-300 text-sm py-2">Learn more</button>
              <Link className="py-2 inline-block text-base lg:text-2xl font-heading font-400 sm:block transition-all cursor-pointer w-100 " to="/responsive-web-development">Learn more</Link>
            */}  
          </article>
        );
      })}
      </div>
      
    </section>
  )
}

export default Services
