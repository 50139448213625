// src/App.js
import React from 'react';
import ReactDOM from 'react-dom';

// Components
import About from "./components/About";
import Sidebar from "./components/Sidebar";
import Work from "./components/Work";
import Services from "./components/Services";
import Experience from "./components/Experience";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";

// // Pages
// import ResponsiveWebDevelopment from './pages/responsive-web-development'


import LazyLoad from 'react-lazyload';
import { Link } from "react-scroll";

function App() {
  return (
    <div className="App text-white snap snap-y snap-mandatory">
      <Sidebar />
      <main className="md:ml-60">
        <About />
        <Experience />
        <Work />
        <Services />
        <Testimonials />
        <Contact />
      </main>
    </div>
  );
}

export default App;
