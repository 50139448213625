import React from 'react'
import { useState } from "react";
import { Link } from "react-scroll";
import { FaPhoneAlt,FaEnvelope,FaLinkedin,FaWhatsapp } from "react-icons/fa";


function Sidebar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const menuItems = [
    {
      "name":"About",
      "link":"about"
    },
    {
      "name":"Experience",
      "link":"experience"
    },
    {
      "name":"Work",
      "link":"work"
    },
    {
      "name":"Services",
      "link":"services"
    },
    {
      "name":"Testimonials",
      "link":"testimonials"
    },
    {
      "name":"Contact",
      "link":"contact"
    }
  ];
  return (
   <aside aria-label="Sidebar" className="w-full drop-shadow-lg lg:drop-shadow-none md:w-60 md:min-h-screen md:fixed justify-center lg:justify-around top-0 md:bottom-0 overflow-y-auto py-4 px-3 bg-black text-white flex flex-wrap flex-col sm:flex-col xs:flex-row  text-center align-center dark:bg-gray-800 sticky z-10">
      <h1 className="space-y-2 text-4xl"><a href="#about">RB<span className="text-orange-400">.</span></a></h1>
        <div
          className={"space-y-2 m-auto inline-block absolute sm:hidden mr-0 ease-in-out top-6" + (!isNavOpen ? " showMenuNav" : " hideMenuNav")}
          onClick={() => setIsNavOpen((prev) => !prev)}
        >
          <span className="block w-8 h-0.5 bg-white"></span>
          <span className="block w-8 h-0.5 bg-white"></span>
          <span className="block w-5 h-0.5 bg-white"></span>
        </div>
  
      <div
          className={"space-y-4 inline-block sm:hidden mr-0 ease-in-out " +
          (isNavOpen ? "showMenuNav" : "hideMenuNav")}
      >
        <div onClick={() => setIsNavOpen(false)}>
          <svg
            className="h-8 w-8 absolute top-5 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
      </div>
      <ul className={ "space-y-2 flex-wrap sm:block mt-4 md:mt-8 py-4 border-orange-400 xs:border-t-2 sm:b-t-0 items-baseline justify-center  ease-in-out duration-300" + (isNavOpen ? " showMenuNav" : " hideMenuNav")}>

        {menuItems.map((item, menuIndex) => {
          return (
            <li key={menuIndex}>
              <Link
                className="py-2 inline-block text-base lg:text-2xl font-heading font-400 sm:block transition-all cursor-pointer w-100 "
                to={item.link}
                onClick={() => setIsNavOpen((prev) => !prev)}
                activeClass="font-black text-orange-400"
                hashSpy={true}
                spy={true}
                smooth={true}
                duration={500}
              >{item.name}<span className="text-white">.</span></Link>
            </li>
          )
        })}
      </ul>

      <div className="flex md:text-xl mt-4 gap-2 justify-center absolute md:relative top-2 right-2">
        <a className="hover:text-black bg-orange-400 text-sm rounded-full p-1 lg:p-2" href="tel:+61423955350"><FaPhoneAlt/></a>
        <a className="hover:text-black bg-orange-400 text-sm rounded-full p-1 lg:p-2" href="mailto:hello@russellbenton.com"><FaEnvelope/></a>
        <a className="hover:text-black bg-orange-400 text-sm rounded-full p-1 lg:p-2" target="_blank" href="https://www.linkedin.com/in/russellbenton"><FaLinkedin/></a>
        {/*<a className="hover:text-black bg-orange-400 rounded-full p-2" target="_blank" href="https://wa.me/+61423955350"><FaWhatsapp/></a>*/}
      </div>
    </aside>
  )
}

export default Sidebar
