import React from "react"
import { testimonials } from './data/testimonials.js';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-scroll";
import { FaStar } from "react-icons/fa";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// import required modulesa
import { Pagination, Navigation, Autoplay } from "swiper";

function Testimonials() {
  return (
    <section id="testimonials" className="relative flex items-center text-black border-b border-b-1 border-orange-200  flex-col justify-center mx-auto px-5 md:px-10 md:min-h-screen py-20 text-center motion-safe:animate-fadeIn snap-start">
      <h2 className="mb-4">Testimonials<span className="text-orange-400">.</span></h2>
      <div className="flex mb-4 text-orange-400 text-2xl"><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></div>
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={true}
          scrollbar={{ draggable: true }}
          className="testimonialSwiper flex m-0 w-full items-center pb-4"
          >
        {testimonials.map((testimonial, key) => (
          <SwiperSlide key={key}>
            <div className="sm:px-4 text-center">
              <div className="relative sm:w-3/4 m-auto">
                <span className="absolute top-0 left-0 md:-left-4  text-left text-7xl md:text-9xl text-orange-400">&ldquo;</span>
                <p className="px-10 md:text-xl line-clamp-6	hover:line-clamp-none">{testimonial.text}</p>
                <span className="absolute bottom-0 right-0 md:-right-4 text-right text-7xl md:text-9xl text-orange-400">&rdquo;</span>
              </div>
              <div className="inline-flex m-auto mt-8 shadow-md border-l-8 rounded-lg border-orange-400 overflow-hidden bg-white justify-center items-center">

                  <img
                    src={require('../images/testimonials/' + testimonial.authorImage)}
                    alt={testimonial.name}
                    className="flex self-stretch w-28 h-full sm:w-48 object-cover"
                  />

                <div className="text-left mx-2 py-6 px-4 flex-1 w-full text-black">
                  <h5 className="font-bold mb-2 uppercase">{testimonial.name}</h5>
                  <p className="italic text-sm mb-4">{ testimonial.role + ' - ' + testimonial.company }</p>
                  <a
                    href={testimonial.websiteUrl}
                    target="_blank"
                    className="btn text-sm px-4 mt-0 py-1">
                      View Website
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Link hashSpy={true} spy={true} smooth={true} duration={500} to="contact" className="bouncingArrow">
        <svg className="h-8 w-8 text-orange-500 m-auto"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
        </svg>
      </Link>
    </section>
  )
}

export default Testimonials
