import React, { useState } from "react";
import { Axios, db } from '../firebase/firebaseConfig.js'
import { services } from './data/services.js';

const Contact = () => {
  const [formData, setFormData] = useState({})

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setFormData({
      name: '',
      email: '',
      url: '',
      message: '',
      service: ''
    })
  }
  const [selectedService, setSelectedService] = React.useState('');

  const handleChange = (event) => {
    setSelectedService(event.target.value);
  };

  const sendEmail = () => {
    Axios.post(
      'https://us-central1-russell-benton-portfolio.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          service: formData.service,
          message: formData.message,
          url: formData.url,
          time: new Date(),
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <section id="contact" className="container mx-auto flex px-5 py-20 md:min-h-screen sm:w-3/4 text-black justify-center items-center flex-col motion-safe:animate-fadeIn text-center snap-start w-full sm:w-3/4">
      <h1 className="mb-6">Contact<span className="text-orange-400">.</span></h1>
      <p className="mb-2">Don't miss out on the opportunity to work with the best front end developer in the business!</p>
      <p className="mb-6">Fill out the contact form and let's start elevating your website to the next level today.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ''}
          className="px-3 py-3 mb-6 text-black bg-white border border-2 border-white relative rounded border-0 shadow outline-none focus:outline-none focus:ring w-full"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ''}
          className="px-3 py-3 mb-6 text-black bg-white border border-2 border-white relative rounded border-0 shadow outline-none focus:outline-none focus:ring w-full"
        />
        <input
          type="url"
          name="url"
          placeholder="Website URL (optional)"
          onChange={updateInput}
          value={formData.url || ''}
          className="px-3 py-3 mb-6 text-black bg-white border border-2 border-white relative rounded border-0 shadow outline-none focus:outline-none focus:ring w-full"
        />
        <select 
          value={selectedService} 
          onChange={handleChange}
          className="px-3 py-3 mb-6 text-black bg-white border border-2 border-white relative rounded border-0 shadow outline-none focus:outline-none focus:ring w-full">
          <option value="">Select a service</option>
          {services.map((service, index) => (
            <option key={index} value={service.title}>
              {service.title}
            </option>
          ))}
        </select>
        <textarea
          type="text"
          name="message"
          placeholder="Message"
          onChange={updateInput}
          value={formData.message || ''}
          className="px-3 py-3 mb-0 text-black bg-white border border-2 border-white relative rounded border-0 shadow outline-none focus:outline-none focus:ring w-full"
        ></textarea>
      <button className="btn animate-pulse w-full text-center bg-orange-400 text-white text-xl uppercase justify-center" type="submit">Submit</button>
      </form>
    </section>
  )
};

export default Contact;
