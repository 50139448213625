export const services = [
  {
    title: "Responsive Web Development",
    description: "Create visually stunning and highly functional websites that adapt seamlessly to various devices, ensuring a consistent user experience on desktops, tablets, and smartphones.",
    icon: "responsive.png",
    iconAlt: "Responsive Web Development Icon"
  },
  {
    title: "E-commerce Solutions (Shopify)",
    description: "Build powerful and user-friendly online stores using Shopify, empowering businesses to showcase their products and increase sales in the competitive e-commerce landscape.",
    icon: "ecommerce.png",
    iconAlt: "E-commerce Solutions Icon"
  },
  {
    title: "Custom WordPress Development",
    description: "Develop bespoke WordPress websites tailored to your unique business needs, combining stunning design with robust functionality and easy content management.",
    icon: "wordpress.png",
    iconAlt: "Custom WordPress Development Icon"
  },
  {
    title: "Vue.js & React Development",
    description: "Harness the capabilities of cutting-edge front-end frameworks like Vue.js and React to build dynamic and interactive web applications that engage users and elevate your online presence.",
    icon: "react-vue.png",
    iconAlt: "Vue.js & React Development Icon"
  },
  {
    title: "Website Performance Optimization",
    description: "Enhance website speed and performance, reducing load times and ensuring an optimal user experience that boosts search engine rankings and drives higher conversion rates.",
    icon: "website-performance.png",
    iconAlt: "Performance Optimization Icon"
  },
  {
    title: "User Experience (UX) Design",
    description: "Craft intuitive and user-centric website interfaces that captivate your audience, improve customer satisfaction, and encourage visitors to take desired actions on your site.",
    icon: "user-experience.png",
    iconAlt: "User Experience (UX) Design Icon"
  },
  {
    title: "Website Maintenance & Support",
    description: "Provide reliable ongoing support and maintenance services to keep your website running smoothly, ensuring security, regular updates, and quick issue resolution.",
    icon: "website-maintenance.png",
    iconAlt: "Website Maintenance & Support Icon"
  },
  {
    title: "Website Audits & Consultation",
    description: "Conduct comprehensive website audits to identify areas for improvement and offer expert advice to optimize your online presence for better performance and results.",
    icon: "website-audit.png",
    iconAlt: "Website Audits & Consultation Icon"
  }
]
