import React from "react"
import { websites } from './data/work.js';
import { Link } from "react-scroll";


function Experience() {
  const tags = [
    {
      "name":"ReactJS",
      "image":"react"
    },
    {
      "name":"Vue.js",
      "image":"vuejs"
    },
    {
      "name":"GraphQL",
      "image":"graphql"
    },
    {
      "name":"Shopify",
      "image":"shopify"
    },
    {
      "name":"HTML",
      "image":"html"
    },
    {
      "name":"CSS",
      "image":"css"
    },
    {
      "name":"JavaScript",
      "image":"javascript"
    },
    {
      "name":"jQuery",
      "image":"jquery"
    },
    {
      "name":"WordPress",
      "image":"wordpress"
    },
    {
      "name":"WooCommerce",
      "image":"woocommerce"
    },
    {
      "name":".NET",
      "image":"dotnet",
      "active":false
    },
    {
      "name":"Adobe Illustrator",
      "image":"illustrator"
    },
    {
      "name":"Adobe Photoshop",
      "image":"photoshop"
    },
    {
      "name":"Figma",
      "image":"figma"
    },
    {
      "name":"mySQL",
      "image":"mysql"
    },
    {
      "name":"cPanel",
      "image":"cpanel"
    },
    {
      "name":"WHM",
      "image":"whm"
    },
    {
      "name":"PHP",
      "image":"php"
    },
    {
      "name":"xCode",
      "image":"xcode",
      "active": false
    },
    {
      "name":"gatsby",
      "image":"gatsby",
      "active": false
    },
    {
      "name":"OpenCart",
      "image":"opencart"
    },
    {
      "name":"Sass/Scss",
      "image":"sass"
    },
    {
      "name":"Tailwind CSS",
      "image":"tailwind"
    }
  ];
  return (
    <section id="experience" className="container relative mx-auto text-black px-5 py-20 flex flex-col flex-wrap md:min-h-screen justify-center items-center motion-safe:animate-fadeIn snap-start">
      <div className="text-center rounded-lg">
        <div className="flex m-auto items-center flex-wrap">
          <div className="w-full lg:w-3/4 m-auto text-center mb-4 md:mb-0">
            <h2 className="mb-8">Experience<span className="text-orange-400">.</span></h2>
            <p className="text-base lg:text-xl mb-4">With nearly 7 years of experience as a freelance front end developer, I've collaborated with a variety of talented individuals to create responsive, fast-loading websites and web products for both businesses and consumers.</p>
            <p className="text-base lg:text-xl mb-8">My main areas of expertise include front-end development, HTML, CSS, and JS, as well as building small and medium web apps with interactive features and layouts. Using best practices, I ensure that every website I create is optimized for success. Whether you're looking to learn more about my skills and experience on my <a className="text-orange-400 font-bold" href="https://www.linkedin.com/in/russellbenton/">LinkedIn</a> profile or ready to get started on your next project, don't hesitate to <a className="text-orange-400 font-bold" href="mailto:hello@russellbenton.co.uk">contact me</a> today.</p>
          </div>
          
          <div className="w-full justify-center flex">
            <div className="w-full gap-2 lg:gap-4 lg:px-10 flex flex-wrap text-center items-center text-center justify-center">
              {tags.filter(tag => tag.active == null).map((tag, key) => {
                return (
                  <div className="w-[calc(33.333%-.5rem)] sm:w-[calc(14.1428%-1rem)] group-hover:rotate-1 relative justify-center text-center items-center group" key={key}>
                    <div className="absolute overflow-hidden inset-0 flex p-2 items-center justify-center group-hover:bg-black shadow-lg opacity-0 group-hover:opacity-95 text-white ease-in-out duration-300 scale-0 group-hover:scale-100 w-full transition font-bold rounded-lg uppercase leading-tight text-sm md:text-lg">{tag.name}</div>
                    <img className="w-full bg-white rounded-lg" src={require('../images/technologies/' + tag.image + '.png')}/>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Link hashSpy={true} spy={true} smooth={true} duration={500} to="work" className="bouncingArrow">
          <svg className="h-8 w-8 text-orange-500 m-auto"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
          </svg>
        </Link>
      </div>
    </section>
  )
}

export default Experience
