export const websites = [
	{
		name: "Australian Internet Advertising",
		url: "https://aiad.com.au/",
		description: "AIA is a Sydney based Online Marketing Agency driven by the obsession with building Adwords, SEO and Social Media campaigns that work!",
		logo: "aiad.png",
		screenshot: ["aiad.png"],
		tags : ["vuejs","html", "css", "javascript", "tailwind", "netlify"],
		primaryColor: "#050f36"
	},
	{
		name: "Panviva Pty Ltd",
		url: "https://www.panviva.com/",
		description: "Panviva is the world's leading cloud knowledge management solution guiding users step-by-step through processes, policies and procedures.",
		logo: "aiad.png",
		screenshot: ["panviva.png", "panviva1.png", "panviva2.png", "panviva3.png"],
		tags : ["react","graphql","html", "css", "jquery", "javascript"],
		active: false
	},
	{
		name: "Benton Watches",
		url: "https://www.benton-watches.com/",
		description: "Based in the Sydney area, Benton Watches is a premier watch brand that offers quality that is the epitome of high calibre watchmaking artistry. Designed in Australia, our collections span across the globe. We strive to deliver a modern take on traditional styles with simplicity in mind and we never overpower, but rather accentuate you’re best features. In line with putting your values on full display, we are dedicated to giving back to the pillars of our community. As a member of an exclusive network, you elevate everyone around you.",
		logo: "benton-watches.png",
		screenshot: ["benton-watches.png"],
		tags : ["shopify","sass","html", "css", "jquery", "javascript"],
		primaryColor: "#968657",
		invert: true
	},
	{
		name: "Cefaly Migraine Treatment",
		url: "https://cefaly.com.au/",
		description: "Cefaly is the best treatment and prevention for migraines. It offers the best safety efficacy ratio compared to current anti-migraine oral medication.",
		logo: "cefaly.png",
		screenshot: ["cefaly.png"],
		tags : ["shopify","javascript","html", "css", "jquery", "javascript", "sass"],
		primaryColor: "#2b3a59",
		invert: true
	},
	{
		name: "Zest X Ldn",
		url: "https://www.zestxldn.co.uk/",
		description: "We’re London’s ultimate private chef service specialising in high end seasonal tasting menus - Think birthday & dinner parties, we take care of it all so all you need to do on the night is relax!",
		logo: "zestxldn-logo.png",
		screenshot: ["zestxldn.png"],
		tags : ["wordpress","woocommerce","html", "css", "php", "javascript", "mysql", "sass"],
		primaryColor: "#0a0a0a"
	},
	{
		name: "Insolvency Experts",
		url: "https://www.insolvencyexperts.com.au/",
		description: "The Insolvency Experts in Sydney proivde free, no obligation 24 advice for Company & Business Liquidation, Voluntary Administration, Bankruptcy & more.",
		logo: "insolvency-experts.png",
		screenshot: ["insolvencyexperts.png"],
		tags : ["vuejs","graphql","html", "css", "jquery", "javascript"],
		primaryColor: "#5b99cc",
		invert: true,
	},
	{
		name: "Stormforce Solutions",
		url: "https://stormforcesolutions.com/",
		description: "Stormforce is a fully licensed and insured roofing & waterproofing company. Offering clients the best variety of solutions in the industry.",
		logo: "stormforce.png",
		screenshot: ["stormforcesolutions.png"],
		tags : ["react","graphql","html", "css", "jquery", "javascript","tailwind","netlify"],
		primaryColor: "#2ea3f2",
		invert:true
	},
	{
		name: "JetPack Aviation",
		url: "https://www.jetpackaviation.com/",
		description: "JPA has been creating VTOL aircraft for over 10 years and are the undisputed leaders in turbine powered, micro VTOLs. Based in Ventura just north of Los Angeles our mechanical, electrical/computer and flight systems engineers have years of experience in designing, prototyping and building VTOL aircraft. We design and build everything in house.",
		logo: "jetpackaviation.png",
		screenshot: ["jetpackaviation.png"],
		tags : ["react","graphql","html", "css", "jquery", "javascript"],
		primaryColor: "#000080"
	},
	{
		name: "United Pool & Renovations",
		url: "https://poolrenovationsandinteriors.com.au/",
		description: "United Pools & Renovations is a family owned business with over 20 years experience. They provide flexible end to end swimming pool resurfacing, renovations and new builds that assist companies and individuals to meet their objectives by giving the best service at an affordable fee.",
		logo: "unitedpools.jpg",
		screenshot: ["unitedpools.png"],
		tags : ["wordpress","html", "css", "jquery", "javascript"],
		primaryColor: "#ffffff"
	},
	{
		name: "OzHealth Pharma",
		url: "https://www.ozhealthpharma.com.au/",
		description: "OzHealth is an Aussie pharmaceutical company dedicated to meeting your health needs. Offering a quality range of products to relieve aches & pain.",
		logo: "ozhealth-pharma.png",
		screenshot: ["ozhealthpharma.png"],
		tags : ["react","graphql","html", "css", "jquery", "javascript"],
		primaryColor: "#ffffff"
	},
	{
		name: "Sofiakorea Jewelry",
		url: "https://www.sofiakorea.com/",
		description: "The coolest, hottest, latest fashion jewelry... all designed and made in Korea! So many of these are prototypes and of limited quantities... and most you will never see again. I’ll add new things every day so do keep checking in and sharing your thought",
		logo: "sofiakorea.png",
		screenshot: ["sofiakorea.png", "sofiakorea1.png", "sofiakorea2.png", "sofiakorea3.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		challenges: [
			"<b>Selling Worldwide</b> - Auto currency conversion based on geo-location.",
			"<b>Ambassador Sign Up</b> - To help grown the brand the customer wanted to allow influencer's to register as a ambassador for Sofiakorea.",
			"<b>Rewarding Ambassadors</b> - If an ambassador generates sales we needed to track this and give them 5% of profits.",
			"<b>Social Media</b> - Managing all social media channels and interacting with followers."
		],
		primaryColor: "#000000",
		invert: true,
		active: false
	},
	{
		name: "Dive Newcastle",
		url: "https://www.divenewcastle.com.au/",
		description: "Dive Newcastle is a Newcastle and The Hunter Region Dive Shop selling scuba diving, spearfishing and snorkeling gear. Air Fills, Australia, Courses, Cylinder Testing, Dive Shop, Equipment, Fills, Gear, Newcastle, Nitrox, Hunter, Service Repair, Dive Newcastle, SSI Courses.",
		logo: "divenewcastle.png",
		screenshot: ["divenewcastle.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		primaryColor: "#000"
	},
	{
		name: "Bin Shop",
		url: "https://www.bin-shop.co.uk/",
		description: "Bin-Shop are an independent supplier and manufacturer of litter and recycling bins, trucks and trolleys and street furniture. Bin-Shop supply a range of products for industrial, commercial and domestic use, and can be used indoor and out. Bin-Shop hand-pick all our products to ensure our customers receive the best, high-quality products available.",
		logo: "bin-shop.png",
		illustrator: "bin-shop.ai",
		screenshot: ["bin-shop.png", "bin-shop1.png", "bin-shop3.png", "bin-shop4.png"],
		tags : ["opencart", "html", "php", "javascript", "css"],
		primaryColor: "#7ec354",
		invert: true
	},
	{
		name: "Medescan",
		url: "https://medescan.com.au/",
		description: "Medescan has been providing high end diagnostic devices & medical equipment worldwide for over 20 years. Buy our products online, for delivery in Australia.",
		logo: "medescan.png",
		screenshot: ["medescan.png"],
		tags : ["shopify", "html", "php", "javascript", "css"],
		primaryColor: "#ffffff"
	},
	{
		name: "The Massage Guys",
		url: "https://www.themassageguys.com.au/",
		description: "The Massage Guys Australia is more than just a massage gun company. We are the future of portable massage guns, with multiple new products currently in production. Our brand represents the future of percussive therapy and portable Massage Guns in Australia.",
		logo: "massage-guys.png",
		screenshot: ["the-massage-guys-home.png"],
		tags : ["html", "css", "javascript", "sass"],
		primaryColor: "#3080ed",
		invert: true
	},
	{
		name: "Institute Of Sport Science",
		url: "https://www.maymanaerospace.com/",
		description: "Our team of experienced professionals is dedicated to helping you improve your health and physical performance. With our innovative programs and cutting-edge treatments, you can achieve the body you’ve always wanted and feel stronger and more confident than ever before.",
		logo: "instituteofsport.png",
		screenshot: ["instituteofsport.png"],
		tags : ["wordpress","php", "css", "jquery", "javascript"],
		primaryColor: "#df4661",
		invert: true
	},
	{
		name: "The Review Guys",
		url: "https://thereviewguys.com.au/",
		description: "Bin-Shop are an independent supplier and manufacturer of litter and recycling bins, trucks and trolleys and street furniture. Bin-Shop supply a range of products for industrial, commercial and domestic use, and can be used indoor and out. Bin-Shop hand-pick all our products to ensure our customers receive the best, high-quality products available.",
		logo: "thereviewguys.png",
		screenshot: ["thereviewguys.png"],
		tags : ["opencart", "html", "php", "javascript", "css"],
		primaryColor: "#ffffff"
	},
	{
		name: "Circa Motorcycle Parts",
		url: "https://circamotorcycleparts.com.au/",
		description: "Circa Motorcycle Parts was born from a love of riding and restoring motorcycles and was established to supply new and used parts for all motorcycles as well as those hiding away in people’s sheds.",
		logo: "circamotorcycleparts.png",
		screenshot: ["circamotorcycleparts.png"],
		tags : ["shopify", "html", "php", "javascript", "css"],
		primaryColor: "#f7482b"
	},
	{
		name: "Dive Newcastle",
		url: "https://www.sharkskin.com/",
		description: "Sharkskin is a revolutionary technical water sports garment range suitable for many different water sports from scuba diving, kayaking, jet skiing, to surfing/",
		logo: "sharkskin.png",
		screenshot: ["sharkskin.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		primaryColor: "#000"
	},
	{
		name: "Bogan Apparel",
		url: "https://www.boganapparel.com.au/",
		description: "Bogan Apparel have created a culture that celebrates all the things Australians are proud to represent. Joining the Bogan Apparel community is a no brainer; you get top end quality (no nipple chafe here), an unmistakable logo, a conversation starter and a free sticker with every order (Who doesn’t love a freebie?).",
		logo: "boganapparel.jpg",
		screenshot: ["boganapparel.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		primaryColor: "#ffffff"
	},
	{
		name: "Procycles Sydney",
		url: "https://www.procycles.com.au/",
		description: "Procycles is one of Sydney’s most established, iconic motorcycle shops with a history dating back over 40 years. Our original store is located in Hornsby, servicing Sydney’s North Shore and Northern beaches and our second store at St Peters covers Sydney’s CBD, Eastern Suburbs, Inner West and south to the Shire.",
		logo: "procycles.png",
		screenshot: ["procycles.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		primaryColor: "#050c76"
	},
	{
		name: "Plaza Dental Care",
		url: "https://www.plazadentalcare.com.au/",
		description: "Plaza Dental Care has been providing dental care in Westfield Tea Tree Plaza, Modbury, SA since the centre opened in 1972. We would love to be your dental clinic of choice in the greater Adelaide area, including Modbury North, Ridgehaven, Valley View, and more.",
		logo: "plazadentalcare.png",
		screenshot: ["plazadentalcare.png"],
		tags : ["shopify","html", "css", "jquery", "javascript"],
		primaryColor: "#a9dae3"
	},
	{
		name: "Mayman Aerospace",
		url: "https://www.maymanaerospace.com/",
		description: "Procycles is one of Sydney’s most established, iconic motorcycle shops with a history dating back over 40 years. Our original store is located in Hornsby, servicing Sydney’s North Shore and Northern beaches and our second store at St Peters covers Sydney’s CBD, Eastern Suburbs, Inner West and south to the Shire.",
		logo: "maymanaerospace.png",
		screenshot: ["maymanaerospace.png"],
		tags : ["wordpress","php", "css", "jquery", "javascript"],
		primaryColor: "#000000"
	},
	{
		name: "MultiMatts",
		url: "https://www.multimatts.co.uk/",
		description: "MultiMatts offer a wide range of solutions providing temporary access, ground protection and ground stabilisation. Our products are commonly used within construction and civil engineering projects, along with the event and leisure industries. They are regularly used to provide trackway, marquee flooring, stadium flooring, exhibit/display flooring, temporary roadways, temporary car parks and pedestrian walkways.",
		logo: "multimatts.png",
		illustrator: "multimatts.ai",
		screenshot: ["multimatts.png", "multimatts1.png", "multimatts2.png", "multimatts3.png"],
		tags : ["opencart", "php", "mysql", "html", "javascript", "css", "jquery"],
		challenges: [
			"<b>Size guide</b> - Customers found it difficult to know the difference between light, medium & heavy duty. We used icons and max weight bearings as labels for each product to make this clearer. In addition to this we used bold colours which make each product really stand out so they know almost instantly.",
			"<b>Categorisation</b> - We used clear imagery and used case studies to help demo uses for the products."
		],
		primaryColor: "#ffffff",
	},
	{
		name: "Gracie Of London",
		url: "https://gracieoflondon.com/",
		description: "We’re London’s ultimate private chef service specialising in high end seasonal tasting menus - Think birthday & dinner parties, we take care of it all so all you need to do on the night is relax!",
		logo: "gracie-london.png",
		screenshot: ["gracie-of-london.png"],
		tags : ["react","graphql","html", "css", "jquery", "javascript"],
		primaryColor: "#000000",
		invert: true
	},
	{
		name: "Mitie Audit Application",
		description: "Mitie Audit Application is an iOS application developed for undertaking audits within prisons. The app allows users to add, complete, review and assign audits. In addition to the iOS application, a single page web application was built which included additional features such as: adding new audits, adjust user permissions, generate PDF's.",
		logo: "mitie.png",
		illustrator: "mitie-audit-app.ai",
		screenshot: ["mitie1.png", "mitie2.png", "mitie3.png", "mitie4.png", "mitie5.png"],
		tags : ["xcode","vuejs", "dotnet", "css", "html"],
		challenges: [
			"<b>Offline App</b> - The audits must be completed in areas that don't have a internet connection so the app was built to store offline usage.",
			"<b>User Permissions</b> - The audit app has four different levels of users whom can all access different features on the app. Each audit must go through multiple user levels in order to be authorised.",
			"<b>Complex Data Storage</b> - Each audit is stored on the server blank with empty fields. Once a user starts an audit it creates a new file on the iPad. Once the audit is complete, it is saved to the iPad until an internet connection is established. Once the connection is established the audit file is removed from the iPad and pushed to the server."
		],
		primaryColor: "#5b1f69",
		active: false
	},
	{
		name: "Cookie Diet Australia",
		url: "https://www.cookiediet.com.au/",
		description: "Dr. Siegal's Cookie Diet is a diet that was designed by Dr. Sanford Siegal who has been treating obesity for over 50 years.",
		logo: "cookiediet.png",
		screenshot: ["cookiediet.png"],
		tags : ["shopify", "liquid", "html", "css", "javascript", "css"],
		primaryColor: "#ff734c"
	},
	{
		name: "Council Grit Bins",
		url: "https://councilgritbins.co.uk/",
		description: "As an established manufacturer and supplier of a range of grit bins and related products, we can offer your council or local authority the most competitive prices for any quantity of grit bins in the UK.",
		logo: "council-gritbins.jpg",
		illustrator: "councilgritbins.ai",
		screenshot: ["councilgritbins.png", "councilgritbins2.png"],
		tags : ["wordpress", "php", "woocommerce", "mysql", "html", "css", "javascript"],
		challenges: [
			"<b>Importing Products</b> - All the products had to be imported from an OpenCart store the WordPress store. This required all the data meta-fields to be corrected to suit WooCommerce standards.",
			"<b>Add To Quote</b> - WooCommerce usually requires you to have a fixed cost price. We wanted to adjust this by having a 'Add to quote' button instead which sent a email to a sales team for quotation."
		],
		active: false
	},
	{
		name: "Home Repair Network Ltd",
		url: "https://hrnl.co.uk/",
		description: "Home Repair Network Limited is a independent Contractor Manager for the UK Property damage industry. The business manages individual networks of approved local Tradespersons and Building Contractors to provide simple ‘hassle free’ services for UK Insurance Companies and Home Owners to manage less complex building damage claims.",
		logo: "hrnl.png",
		screenshot: ["hrnl.png", "hrnl2.png", "hrnl3.png", "hrnl4.png"],
		tags : ["wordpress","html", "css", "jquery", "javascript"],
		primaryColor: "#0093b2",
		invert: true
	},
	{
		name: "Digital Signage",
		description: "Digital Signage is is a freestanding information board where customers can load content on. I worked on several signage display within the custodial sector.",
		logo: "unify-white.png",
		screenshot: ["signageHB1.png", "signageHB4.png", "signageHB2.png", "signageHB3.png"],
		tags : ["html", "css", "javascript", "sass", "jquery"],
		primaryColor: "#243f56",
		active:false
	},
	{
		name: "Wise Designs",
		url: "http://wisedesigns.co.uk/",
		description: "Wise Designs is a bespoke web development company based in Rotherham, South Yorkshire. Passionate about helping small to medium sized businesses to progress their companies further, Wise Designs provides a wide range of services, for example web design and marketing, in order to help your business strive.",
		logo: "wisedesigns.png",
		illustrator: "wisedesigns.ai",
		screenshot: ["wisedesigns.png"],
		tags : ["wordpress", "php", "css", "sass", "javascript"],
		primaryColor: "#42aa9d",
		active: false
	},
	{
		name: "Rapid Spill",
		url: "https://rapidspill.co.uk",
		description: "RapidSpill specialise in supplying a high-quality range of spill kits, absorbents and spill containment products at competitive prices.",
		logo: "frontline-ss.png",
		screenshot: ["rapidspill.png"],
		tags : ["opencart", "php", "html", "css", "sass"],
		active: false
	},
]
